<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import BtnAddProductReason from "@/feature/annual_report/presentation/views/components/btn_add_product_reason.vue";
import AnnualReportServices from "@/feature/annual_report/data/services/annual_report_services";
import ReasonsMultiSelect from "@/feature/annual_report_v2/presentation/components/ReasonsMultiSelect.vue";
import TableWithCountField from "@/feature/annual_report_v2/presentation/components/TableWithCountField.vue";


const data = [{
  producto: "",
  contador_producto: null,
  descripcion_producto: "",
  descripcion_motivo: "",
  motivo: [],
  motivo_detail: [],
  contador_motivo: null
}]

export default {
  name: "ProductReasonsWithTableField",
  components: { ReasonsMultiSelect, BtnAddProductReason, Accordion, TableWithCountField },
  props: {
    urlGetCounter: {
      type: String,
      default: undefined
    },
    labelTotalCounter: {
      type: String,
      default: undefined
    },
    labelInstanceCounter: {
      type: String,
      default: undefined
    },
    hideInstanceCounter: {
      type: Boolean,
      default: false
    },
    hideTopInputs: {
      type: Boolean,
      default: false
    },
    labelProductCounter: {
      type: String,
      default: undefined
    },
    labelReasonsCounter: {
      type: String,
      default: undefined
    },
    totalCounter: {
      type: [Number, String],
      default: "",
    },
    instanceCounter: {
      type: [Number, String],
      default: "",
    },
    products_reasons: {
      type: Array,
      default: () => JSON.parse(JSON.stringify(data))
    },
    soloPreview: {
      type: Boolean,
      default: false
    },
    company_id: {
      type: String,
      default: undefined
    }
  },
  data: () => ({
    showDescriptionProduct: false,
    showDescriptionReasons: false,
    selectedIdsReasons: [],
    selectedReasonsDetail: [],
    optionsReasons: [],
    optionsProducts: [],
    selectedReasons: [],
  }),
  created() {
    this.initDataProductReasons(this.company_id)
    if (this.products_reasons.length === 0) {
      this.productReasonsModel = JSON.parse(JSON.stringify(data))
    }
  },
  watch: {
    async company_id(newVal, oldVal) {
      console.log('WATCH COMPANY', newVal, oldVal)
      await this.initDataProductReasons(newVal)
    }
  },
  methods: {
    async initDataProductReasons(value) {
      if (value) {
        this.optionsReasons = await this.$store.dispatch(
          "form/loadReasonsOptions",
          { company: value }
        );
        this.optionsProducts = await this.$store.dispatch(
          "form/loadProductsOptions",
          { company: value }
        );
        const otherOption = {
          "id": 0,
          "name": "OTROS"
        }
        this.optionsProducts.push(otherOption);
        
        this.$forceUpdate();
      }
      
    },
    onChangeReasons(values, item) {
      item.motivo = values.map((val) => val.id.toString())
      console.log(item.motivo, item.motivo.includes('99'), !!item.motivo.includes('99'))
      this.showDescriptionReasons = !!item.motivo.includes('99');
    },
    onAdd() {
      this.productReasonsModel.push({ ...JSON.parse(JSON.stringify(data[0])) })
    },
    onDelete(index) {
      if (!this.soloPreview) {
        this.productReasonsModel.splice(index, 1)
      }
    },
    async onBlurTotalCounter(evt, val) {
      if (!this.hideInstanceCounter && val!= undefined) {
        const response = await this.getCounters();
        this.instanceCounter = String(response.complaint_count)
        
        this.$emit("update:instance-counter", this.instanceCounter)
        if (this.totalCounterModel != this.instanceCounter) {
          this.$notification.close("counter");
          this.$notification.warning({
            key: 'counter',
            message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
          });
        }
      }
    },
    async getCounters() {
      let json = {
        "entity_code": this.company_id,
        "products": [],
        "reasons": [],
      }
      let data = await AnnualReportServices.getCounters(json, this.urlGetCounter);
      if (!data.isSuccess) {
        this.$notification.close("request");
        this.$notification.warning({
          key: 'request',
          message: data.message
        });
      }
      return data.data;
    },
    updateReasonsList(index, reasonsIds, reasonsDetail){
      this.products_reasons[index].motivo = reasonsIds;
      this.products_reasons[index].motivo_detail = reasonsDetail; 
      this.productReasonsModel = this.products_reasons;
    }
  },
  
  computed: {
    totalReasons(uno=0) {
      return this.selectedReasonsDetail.reduce(
        (sum, item) => {
          return sum + Number(item.count) + uno;
        },
        0
      );
    },
    productReasonsModel: {
      get() {
        return this.products_reasons
      },
      set(value) {
        console.log('sst', value)
        this.$emit("update:products_reasons", value)
      }
    },
    totalCounterModel: {
      get() {
        return this.totalCounter
      }, 
      set(value) {
        this.$emit("update:totalCounter", value)
      }
    },

  },
}
</script>

<template>
  <div class="w-full">
    <div class="grid grid-cols-2 gap-x-8 mb-6" v-if="!hideTopInputs">
      <BaseInput type="number" numeric :label="labelTotalCounter" :required="true" @blur="onBlurTotalCounter"
        v-model="totalCounterModel" :disabled="soloPreview" />

      <BaseInput v-if="!hideInstanceCounter" :label="labelInstanceCounter" :disabled="true"
        v-model="instanceCounter" />
    </div>
    <div class="w-100 mb-6">
      <Accordion v-for="(item, index) in productReasonsModel" :key="index" :open="false" :disable="false"
        class="w-100 mt-4" header="Producto y motivo" :is-delete="index > 0" @deletecomponent="onDelete(index)">
        <div class="w-full grid grid-cols-2 gap-y-6 gap-x-10 justify-end items-end">
          <BaseSelect label="Producto" :selectOptions="optionsProducts" v-model="item.producto"
            :disabled="soloPreview" />
          <BaseInput type="number" numeric :label="labelProductCounter" v-model="item.contador_producto"
            :disabled="soloPreview" />
          <BaseTextarea v-if="item.producto == '0'" class="col-start-1 col-span-2" label="Descripción" maxlength="100"
            v-model="item.descripcion_producto" :disabled="soloPreview" />
          
          <TableWithCountField class="mt-0 col-start-1 col-span-2 text-left"
            :selectedItemsIds="item.motivo" :selectedItemsDetail="item.motivo_detail"
            :options="optionsReasons" :soloPreview="soloPreview" :totalCounterModel="item.contador_producto"
            @update-items="(reasonsIds, reasonsDetail) => updateReasonsList(index, reasonsIds, reasonsDetail)"
          />

          <BaseTextarea v-if="!!item.motivo.includes(99)" class="col-start-1 col-span-2" label="Descripción"
          maxlength="100" v-model="item.descripcion_motivo" :disabled="soloPreview" />

        </div>
      </Accordion>
      <BtnAddProductReason v-if="!soloPreview" @click="onAdd" />
    </div>
  </div>
</template>